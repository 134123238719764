import { compact } from "lodash";
import { Custom_Build } from "../backend-generated-types";

export const get_number_of_components = (cb: Custom_Build): number => {
    return (
        compact([cb.case, cb.motherboard]).length
        + (cb.case_fans?.length ?? 0)
        + (cb.cpus?.length ?? 0)
        + (cb.cpu_coolers?.length ?? 0)
        + (cb.gpus?.length ?? 0)
        + (cb.operating_systems?.length ?? 0)
        + (cb.psus?.length ?? 0)
        + (cb.rams?.length ?? 0)
        + (cb.storages?.length ?? 0)
    );
}

export function sanitize_input(input: string): string {
    return input.replace(/[^a-zA-Z0-9 ]/g, "");
}

export function sanitize_number_input(input: string): string {
    return input.replace(/[^0-9.]/g, "");
}

export const to_title_case = (str: string): string => {
    return str
        .split(' ') // Step 2: Split the string into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Step 3: Capitalize the first letter of each word
        .join(' '); // Step 4: Join the words back into a single string
};

export function format_spec_json(specs?: string): string[] {
    if (!specs) {
        return [];
    }
    const jsonObject = JSON.parse(specs);
    if (typeof jsonObject !== 'object' || jsonObject === null) {
        return [];
    }

    const formattedArray: string[] = [];
    for (const [key, value] of Object.entries(jsonObject)) {
        if (key && value && typeof value === 'string')
            formattedArray.push(`${to_title_case(key.replaceAll('_', ' '))}: ${to_title_case(value.replaceAll('_', ' '))}`);
    }

    return formattedArray;
}